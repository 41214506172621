import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cx from 'classnames'

import Img from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Grid from "components/Grid"

import styles from './about.module.scss'

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      teamPics: allFile(filter: { relativeDirectory: { eq: "team-core" } }) {
        nodes {
          name
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      sponsors: allFile(
        filter: { relativeDirectory: { eq: "fc/sponsors" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      teamData: allTeamJson {
        edges {
          node {
            id
            name
            img
            twitter
            role
          }
        }
      }
    }
  `)

  const teamData = data.teamData.edges
  const teamPics = data.teamPics.nodes
  const sponsors = data.sponsors.nodes

  const getImgSrc = name =>
    teamPics.filter(image => {
      if (!image.name.includes(name)) {
        return null
      }

      return image
    })[0].childImageSharp.fluid

  return (
    <Layout className={styles.page}>
      <SEO title="About" />
      <section className={cx(styles.root, "pgHead")}>
        <div
          className={cx(styles.root, "content")}
          style={{ padding: "0 2em" }}
        >
          <h1 className={cx(styles.title, "boldColor content-max-width")}>
            Feeding Community was founded to fill important gaps in food
            security exacerbated by the Covid19 pandemic.
          </h1>
        </div>
      </section>
      <section className={cx(styles.root, "content")}>
        {/* <h1
          className={cx(
            styles.title,
            "boldColor content-max-width"
          )}
        >
          Feeding Community was founded to fill important gaps in food security
          exacerbated by the Covid19 pandemic.
        </h1> */}
        <section>
          {/* <img
            className={cx(styles.img)}
            src="https://res.cloudinary.com/afrodeli/image/upload/v1611637040/feedingcommunity_bpkq6u.jpg"
            alt="g"
          /> */}
          <Grid col={2} gap={3} className={cx(styles.root, "mt--l mb--lg")}>
            <section>
              {/* <img
                className={cx(styles.img)}
                src="https://res.cloudinary.com/afrodeli/image/upload/v1611637040/feedingcommunity_bpkq6u.jpg"
                alt="g"
              /> */}
              <h2 className={styles.gridCol12}>Our Mission</h2>
              <p className={styles.gridCol12}>
                Provide nutritious, culturally-appropriate meals to community
                members in need in the Twin Cities Metro.
              </p>
              <h2 className={styles.gridCol12}>Our Vision</h2>
              <p className={styles.gridCol12}>A hunger free world.</p>
            </section>
            <section>
              <h2 className={styles.gridCol12}>Our Values</h2>
              <ol>
                <li className={styles.gridCol12}>
                  We believe in <b>people</b> and <b>food</b>.
                </li>
                <li>
                  We believe in <b>collaboration</b> and <b>community</b>.
                </li>
                <li>
                  We believe everyone deserves <b>access</b>: to{" "}
                  <b>healthy food, safe housing, and economic opportunities</b>.
                </li>
              </ol>
            </section>
          </Grid>

          <h2 className={styles.gridCol12}>Born from need</h2>
          <p className={styles.gridCol12}>
            The number of Minnesotans who struggle to put enough food on the
            table remains at its highest level and the Covid19 pandemic has only
            made it worse.
          </p>
          <p className={styles.gridCol12}>
            With more Minnesotans out of work or forced to stay home for health
            reasons, Feeding Community has partnered with some amazing
            organizations to provide an important lifeline. Most importantly as
            a Halal only provider, our unique services meet a growing need in
            the Twin Cities metro and across the state.
          </p>

          <p className={styles.gridCol12}>
            <b>
              With your support and the support of our amazing partners, we plan
              to distribute over 1 million meals by the end of 2021.
            </b>
          </p>
        </section>

        {/* <div
          className={cx(styles.root, "mt--l mb--lg")}
          style={{ padding: "" }}
        >
          <Grid col={2} gap={3}>
            <section>
              <h3>Our Mission</h3>
              <p className={styles.gridCol12}>
                Provide nutritious, culturally-appropriate meals to community
                members in need in the Twin Cities Metro.
              </p>
            </section>
            <section>
              <h3>Our Vision</h3>
              <p className={styles.gridCol12}>A hunger free world.</p>
            </section>
          </Grid>
        </div> */}

        <div className={cx(styles.aeds, "mt--l mb--lg")}>
          <div className={styles.aeds1}>
            <h1 className={cx(styles.subtitle, "content-max-width")}>
              Our Food.
            </h1>
            <img
              className={cx(styles.aedslogo)}
              src="https://res.cloudinary.com/afrodeli/image/upload/v1611848922/PXL_20201008_150527660.PORTRAIT-01.COVER_crlc3z.jpg"
              alt="Our Food"
            />
          </div>
          <div className={styles.aeds1}>
            <p className={styles.gridCol12}>
              We focus on providing culturally-appropriate, nutritious meals
              that are halal. As our state continues to grow even more diverse,
              there is a growing need to support communities that need halal,
              and kosher, options.{" "}
            </p>
            <p className={styles.gridCol12}>
              Culturally, our food speaks to and resonates with a wide spectrum
              of identities and communities. With our direct catering and now
              our home meal service, we provide a robust menu that provides
              variety for our clients and includes healthy options that are
              based on current federal nutrition standards.
            </p>
          </div>
        </div>

        {/* <div className={cx(styles.aeds, "mt--l mb--lg")}>
          <div className={styles.aeds1}>
            <img
              className={cx(styles.aedslogo)}
              src="https://res.cloudinary.com/afrodeli/image/upload/v1611848920/aedslogo_ezjzfk.png"
              alt="African Economic Development Solutions (AEDS)"
            />
          </div>
          <div className={styles.aeds1}>
            <h1 className={cx(styles.subtitle, "content-max-width")}>
              Our Fiscal Sponsor.
            </h1>
            <p className={styles.gridCol12}>
              We are grateful to have African Economic Development Solutions
              (AEDS) as our fiscal sponsor and lean on their experience and
              expertise on navigating our administrative responsibilities. Their
              support allows us to serve more community members and make more of
              a difference.
            </p>
          </div>
        </div> */}

        <div className={cx(styles.partners, "mt--xl")}>
          <h1 className={cx(styles.subtitle, "content-max-width")}>
            These are some of the incredible organizations we partner with.
          </h1>
          <div className={styles.logoGroup}>
            {sponsors.map(logo => (
              <div key={logo.id} className={styles.logoItem}>
                <Img
                  className={cx(styles.logo)}
                  fluid={logo.childImageSharp.fluid}
                  alt={logo.name}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
